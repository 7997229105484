import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-west-palm-beach-florida.png'
import image0 from "../../images/cities/scale-model-of-west-palm-beach-waterfront-visitor-information-center-in-west-palm-beach-florida.png"
import image1 from "../../images/cities/scale-model-of-pga-national-golf-club-in-west-palm-beach-florida.png"
import image2 from "../../images/cities/scale-model-of-historical-society-of-palm-beach-county-in-west-palm-beach-florida.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='West Palm Beach'
            state='Florida'
            image={image}
            lat='26.7153424'
            lon='-80.05337459999998'
            attractions={ [{"name": "West Palm Beach Waterfront Visitor Information Center", "vicinity": "100 N Clematis St, West Palm Beach", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 26.7135791, "lng": -80.04981020000002}, {"name": "PGA National Golf Club", "vicinity": "400 Ave of the Champions, Palm Beach Gardens", "types": ["store", "point_of_interest", "establishment"], "lat": 26.8284102, "lng": -80.14199859999997}, {"name": "Historical Society of Palm Beach County", "vicinity": "300 N Dixie Hwy, West Palm Beach", "types": ["museum", "point_of_interest", "establishment"], "lat": 26.7156986, "lng": -80.05301209999999}] }
            attractionImages={ {"West Palm Beach Waterfront Visitor Information Center":image0,"PGA National Golf Club":image1,"Historical Society of Palm Beach County":image2,} }
       />);
  }
}